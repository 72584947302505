<template>
  <div class="transactions">
    <router-view @dashboard-event="(et) => $emit('dashboardEvent', et)"></router-view>
  </div>
</template>

<script>
export default {
  name: "Transactions"
};
</script>
